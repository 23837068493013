import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { eventsHolder } from "./events.module.scss";

export default function EventsPage() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativeDirectory: { eq: "events" } }, sort: { fields: relativePath }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(height: 700, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const images = data.allFile.nodes;

  console.log(images);

  return (
    <article className={eventsHolder}>
      <h1>Events</h1>
      <section>
        <h4>There are currently no Girls Girls Girls events planned.</h4>
      </section>
      <section className="posters">
        <h3>Previous Events</h3>
        <ul>
          {images?.map((img) => {
            const eventImage = getImage(img);
            return (
              <li>
                <GatsbyImage key={img.relativePath} alt={img.relativePath} image={eventImage} />
              </li>
            );
          })}
        </ul>
      </section>
    </article>
  );
}
